<template>
    <div>
        <BHLoading :show="loading" />
        <a-modal :visible="preview" :footer="null" @cancel="preview = false" @close="preview = false">
            <h3>Previewing Contacts ({{previewList.length}} of {{previewCount}})</h3>
            <a-card v-for="(item,itemI) in previewList" :key="itemI">
                <ul>
                    <template v-for="([key,value]) in Object.entries(item)">
                        <li  :key="key + 'item'" v-if="typeof value === 'string'">
                            {{key}}:
                            <template v-if="typeof value === 'string'">
                                <strong v-if="value && value.trim()">{{value}}</strong>
                                <em v-else class="text-med-gray">Empty</em>
                            </template>
                        </li>
                    </template>
                </ul>
            </a-card>
        </a-modal>
        <div v-if="parsedResults ==null" class="w-full" style="text-align: center; margin-left: auto; margin-right: auto;">
            <div v-if="checking && stats.processing && stats.progress < 100">
                <p class="mt-3">Importing Contacts</p>
                <span>ETA : {{ stats.eta }} </span>
				<div>
					<a-progress :percent="stats.progress" status="active" :format="() => `${stats.count}/${stats.total}  (${stats.progress}%)`"/>
				</div>
				<div v-if="stats.count === 0" class="dF aC jSA">
					<a-alert class="mt-3" message="Your process has been placed in queue will process shortly." type="info" show-icon style="width: fit-content;">
					</a-alert>
				</div>
				<a-button v-else class="mt-3" type="danger" @click="cancelImport">
					Stop Import
				</a-button>
            </div>
            <div v-else-if="checking">
                <h3>Checking processing...</h3>
            </div>
            <div v-else>
                <p class="mt-3">Choose file to get started </p>
                <div style="text-align:center">
                    <a-button @click="uploadFile" class="mt-2" size="large" type="primary">BROWSE</a-button>
				</div>
				<div class="dF aC jSA">
					<a-alert class="mt-3" type="warning" show-icon style="width: fit-content;">
						<template slot="message">
							Bildhive has a maximum upload of <strong style="color: red">15,000</strong> leads at a time.
						</template>
					</a-alert>
				</div>
				<div class="dF aC jSA">
					<a-alert class="mt-3" type="info" show-icon style="width: fit-content;">
						<template slot="message">
							Download a <strong class="link" @click="downloadFile('sample')"> SAMPLE FILE </strong> containing instructions and a <strong class="link" @click="downloadFile('template')">TEMPLATE FILE</strong> with all the headings that can be mapped to Bildhive fields.
						</template>
					</a-alert>
				</div>
            </div>

        </div>
        <div v-if="parsedResults !== null" class="mx-3">
			<a-steps :current="current" class="mb-5">
				<a-step title="Step 1" description="Field Mapping" />
				<a-step title="Step 2" description="Verification Process" />
			</a-steps>

			<div class="f1">
				<transition  name="slide-fadein-right" mode="out-in">
					<div>
						<template v-if="current === 1">
							<a-row class="my-3" :gutter="16">
				                <a-col :span="8" class="dF aC"><strong>CSV Headings</strong></a-col>
				                <a-col :span="12" class="dF aC"><strong>Fields</strong></a-col>
								<a-col :span="4" class="dF aC"><strong>Preserve Field</strong></a-col>
				            </a-row>
				            <div v-for="(result, resultI) in parsedResults[0]" :key="resultI">
				                <a-row class="mb-3" :gutter="16">
				                    <a-col :span="8" class="dF aC">
				                        <div :class="fields[resultI] ? '' : ''">
				                            {{ result }} <a-icon v-if="fields[resultI] && fields[resultI] !== 'ignore'" class="ml-2 text-primary" type="check-circle" />

				                        </div>
				                    </a-col>
				                    <a-col :span="12" class="dF aC">
				                        <a-select aria-placeholder="Ignore" placeholder="Ignore" v-model="fields[resultI]" @change="(e) => selectField(e, resultI)" style="width:100%">
				                            <a-select-opt-group>
				                                <span slot="label"><a-icon type="user" /> Default Fields</span>
				                                <a-select-option v-for="field in contactFields" :key="field.value">
													<a-tooltip v-if="field.tooltip" overlayClassName="change-tooltip-color" placement="left">
														<template slot="title">
															{{ field.tooltip }}
						                                </template>
														{{ field.label }}
						                                <a-icon type="question-circle" style="font-size: 14px" />
						                            </a-tooltip>
													<span v-else>
														{{ field.label }}
													</span>
												</a-select-option>
				                            </a-select-opt-group>
				                            <a-select-opt-group>
				                                <span slot="label"><a-icon type="profile" /> Custom Fields</span>
				                                <a-select-option v-for="field in customFields" :key="field.id">{{ field.name }}</a-select-option>
				                            </a-select-opt-group>
				                        </a-select>
				                    </a-col>
									<a-col :span="4">
										<a-checkbox :disabled="!fields[resultI] || fields[resultI] == 'ignore'" :checked="fieldOptions[resultI] && fieldOptions[resultI].preserveOriginal" @change="(e) => preserveField(e, resultI)"></a-checkbox>
									</a-col>
				                </a-row>
				            </div>
				            <div class="mt-4">
				                <a-select v-model="contactTags" mode="tags" style="width: 100%" placeholder="Add tags">
				                    <a-select-option v-for="(tag, tagI) in tags" :value="tag.id" :key="tag.name + tagI">
				                        {{ tag.name }}
				                    </a-select-option>
				                </a-select>
				            </div>
				            <div class="mt-4">
				                <p class="text-danger mb-0" v-if="error.emailError">Email field must be selected to continue.</p>
				                <p class="text-danger" v-if="error.duplicateError">You have duplicate fields selected.</p>
				            </div>
						</template>
						<template v-else-if="current === 2">
							<div class="mx-3">
								<a-checkbox
									v-model="processVerification"
									:disabled="instance.isVerificationsOn"
								>
									Verify your list before importing
									<a-tooltip overlayClassName="change-tooltip-color">
                                        <template slot="title">
                                            Verify the list before importing. The credit card on file for this project will be charged upon verification completion.
                                        </template>
                                        <a-icon
                                            type="question-circle"
                                            style="
                                                font-size: 12px;
                                                color: black;
                                                margin-left: 5px;
                                            "
                                        />
                                    </a-tooltip>
								</a-checkbox>
								<a-alert v-if="instance.isVerificationsOn" class="mt-3" type="error" show-icon style="width: fit-content;">
									<template slot="message">
										Your previous broadcast exceeded the <strong style="color: red">4% bounce threshold.</strong> Before importing you will have to either verify your lead list or clean your bounces using the <strong class="cursor-pointer" style="color: red" @click="$emit('changeTab', '4')">Bulk Delete Tab</strong> to maintain your reputation and avoid suspension.
									</template>
								</a-alert>
								<a-alert v-if="!instance.isVerificationsOn" class="mt-3" type="info" show-icon style="width: fit-content;">
									<template slot="message">
										We recommend to verify your list before importing it to maintain low bounce rates on future broadcasts.
									</template>
								</a-alert>
								<div class="dF aC mt-3" style="gap: 20px" v-if="processVerification && charges">
									<div style="font-size: 24px;">
										Verification Charges:
										${{ charges && charges.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) }} {{currency === 'CAD' ? '(CAD)' : ''}}
									</div>
									<a-select
										v-model="currency"
										:default-value="{ key: 'USD' }"
										style="width: 120px"
										@change="getVerificationCost"
									>
										<a-select-option value="USD">
										$ USD
										</a-select-option>
										<a-select-option value="CAD">
										$ CAD
										</a-select-option>
									</a-select>
								</div>
							</div>
						</template>
					</div>
				</transition>
			</div>

			<div>
				<a-divider />
				<div class="dF jSB mt-3">
					<template v-if="current === 1">
						<a-button size="large" type="secondary" @click="cancel">CANCEL</a-button>
						<div class="dF aC" style="gap: 20px">
		                    <a-tooltip title="Preview the mapping of the fields" overlayClassName="change-tooltip-color">
		                        <a-button icon="eye" @click="previewModal" size="large">Preview</a-button>
		                    </a-tooltip>
							<a-button size="large" type="primary" @click="onNextStep">NEXT</a-button>
		                </div>
					</template>
					<template v-else-if="current === 2">
						<a-button size="large" type="secondary" @click="current--">BACK</a-button>
						<a-button size="large" type="primary" @click="importContacts">IMPORT</a-button>
					</template>
				</div>
			</div>
        </div>
    </div>
</template>
<script>
import BHLoading from 'bh-mod/components/common/Loading'
import {validateEmail} from 'bh-mod'
export default {
    components:{
        BHLoading
    },
    data() {
        return {
            checking:true,
			importTrigger: false,
            checkTimeout:null,
            fileToSend:{
                url:null,
                file:null
            },
            previewCount:0,
            previewList:[],
            preview:false,
            showError:false,
            error:{},
            loading:false,
            contactTags:[],
            parsedResults: null,
            contactFields: [
				{ label: "Ignore", value: "ignore" },
				{ label: "Address", value: "address" },
				{ label: "City", value: "city" },
				{ label: "Company", value: "company" },
				{ label: "Consent", value: "consent" },
				{ label: "Contact Type", value: "status" },
				{ label: "Country", value: "country" },
				{ label: "Description", value: "description" },
				{ label: "Email Address", value: "email" },
				{ label: "First Name", value: "firstName" },
				{ label: "Job Title", value: "jobTitle" },
				{ label: "Last Name", value: "lastName" },
				{ label: "Interaction Activity", value: "leadScore", tooltip: "This helps sales determine the amount of interactions the lead has had in regards to form submission, email interaction." },
				{ label: "Lead Status", value: "leadStatus" },
				{ label: "Middle Name", value: "middleName" },
				{ label: "Phone", value: "phone" },
				{ label: "Postal Code", value: "postal" },
				{ label: "Prefix", value: "prefix" },
				{ label: "Realtor?", value: "isAgent" },
				{ label: "Region", value: "region" },
				{ label: "Source", value: "source" },
				{ label: "Source URL", value: "sourceUrl" },
				{ label: "Suffix", value: "suffix" },
				{ label: "Tag", value: "tag" },
				{ label: "Unsubscribed", value: "unsub" }
			],
            fields:[],
			fieldOptions:{},
            importProgress:null,
			currency: 'USD',
			current: 1,
			charges: 0,
			processVerification: false
        };
    },
    computed:{
		instance() {
            return this.$store.state.instance
        },
        settings() {
            return this.$store.state.contacts.allSettings
        },
        stats(){
            let stats = this.importProgress && this.importProgress.progress;

            if (!stats || typeof stats !== 'string' || stats.split(':').length !== 2 || isNaN(stats.split(':')[0]) || isNaN(stats.split(':')[1])){
                return {
                    processing:false
                }
            }

            let [count,total] = stats.split(':');
            count = parseInt(count);
            total = parseInt(total);
            return {
                processing:true,
                count,
				total,
                progress:Math.round((count / total) * 100),
				eta: this.importProgress && this.importProgress.eta
            }
        },
        fieldLookup(){
            let fieldLookup = {}
            this.contactFields.forEach( ({label,value}) => {
                fieldLookup[value] = label
            })
            return fieldLookup
        },
        cFieldLookup(){
            let cFieldLookup = {}
            Object.values(this.customFields).forEach( ({name,id}) => {
                cFieldLookup[id] = name
            })
            return cFieldLookup
        },
        customFields() {
            return this.$store.state.contacts.fields || {}
        },
        tags(){
            return this.$store.state.contacts.tags
        }
    },
    watch:{
        checking:{
            immediate:true,
            handler(val){
                if (val){
                    this.intervalCheck()
                } else {
                    this.importProgress = null
                    clearInterval(this.checkTimeout)
                }
            }
        },
		processVerification: {
			immediate:true,
            handler(val){
				if(val){
					this.getVerificationCost()
				}
            }
		},
		instance: {
			deep: true,
			immediate: true,
			handler(val) {
				if (val) {
					this.processVerification = val.isVerificationsOn;
					if(val.isVerificationsOn == false) {
						this.parsedResults = null;
						this.current = 1;
					}
				}
			}
		}
    },
    methods: {
		uploadFile(){
			this.$store.commit('SET_MULTIPLE_IMAGES', false)
			this.$store.commit('MEDIA_SELECT',{callback:this.selectFile,type:'.csv'})
		},
        callProgress(){
            return new Promise((resolve) => {
                this.$api.get(`/contacts/:instance/import-progress`)
                    .then( ({data}) => {
						const progress = data && data.progress
                        if (!progress || typeof progress !== 'string' || progress.split(':').length !== 2 || isNaN(progress.split(':')[0]) || isNaN(progress.split(':')[1])){
                            if ((this.importProgress && this.importProgress.progress) || this.importTrigger) {
								this.$router.go();
							}
							return resolve(false)
                        }
                        this.importProgress = data;
                        return resolve(true)
                    })
                    .catch( () => {
                        resolve(false)
                    })
            })

        },
        async intervalCheck(){
            let result = await this.callProgress()
            if (!result) {
                this.checking = false;
            }
            this.checkTimeout = setInterval( async () => {
                result = await this.callProgress()
                if (!result){
                    this.checking = false;
                }
            }, 4000);
        },
        previewModal(){
            let validfields = this.fields.filter(x => x && x !== 'ignore')
            if (validfields.length === 0) return this.$message.error('You do not have any fields mapped. Please do so before previewing')
            let list = this.createContacts()
            this.previewCount = list.length
            list = list.splice(0,3)

            this.previewList = list.map(each => {
                let obj = {}
                Object.entries(each).forEach( ([key,value]) => {
                    let fieldLabel = this.fieldLookup[key] || this.cFieldLookup[key]
                    if (fieldLabel) {
                        obj[fieldLabel] = value
                    } else if (this.cFieldLookup[fieldLabel]){
                        obj[fieldLabel] = value
                    }
                })
                return obj
            })
            if (!this.previewList.length) return this.$message.error('There was an error previewing. Please make sure you have the email field mapped correctly.')
            this.preview = true


        },
        hasError(){
            let validFields = this.fields.filter(x => x && x !== 'ignore')
            let uniqueFields = Array.from(new Set(validFields))

            let duplicateError = validFields.length !== uniqueFields.length
            let emailError = !validFields.includes('email')
            this.error = {
                duplicateError,
                emailError
            }
            return duplicateError || emailError
        },
        selectFile(file) {
            this.beforeUpload(file)
        },
        cancel(){
            this.parsedResults = null
            this.fields = []
        },
        createContacts(){

            let [header,...theRest] = this.parsedResults

            let contacts = []

            theRest.forEach( contactFields => {

                let obj = {
                    others:{
                        customFields:{}
                    }
                }

                this.fields.forEach( (field,fieldI) => {
                    if (field && field !== 'ignore'){
                        if (this.cFieldLookup[field]){
                            obj.others.customFields[field] = {
                                ...this.customFields[field],
                                value:contactFields[fieldI],
                                options:{
                                    default:contactFields[fieldI],
                                }
                            }
                        }
                        obj[this.fields[fieldI]] = contactFields[fieldI]
                    }
                })
                contacts.push(obj)
            })

            contacts.forEach((contact) => {
                delete contact.ignore
                delete contact.undefined
            })


            return contacts.filter(x => {
                let email = x.email && x.email.trim()
                if (!email) return false
                return validateEmail(email)
            })
        },
        importContacts(){
            this.showError = false
            let hasError = this.hasError()

            if (hasError) {
                this.showError = true
                return this.$message.error('There are errors in your submission')
            }

            // let contacts = this.createContacts()
            // return chunks
            let oldTags = []
            let newTags = []
            let storeTags = Object.values(this.tags)

            this.contactTags.forEach(tag => {
                let index = storeTags.findIndex(x => x.id == tag)
                if (index !== -1) {
                    oldTags.push(tag)
                } else {
                    newTags.push(tag)
                }
            })

            this.$store.commit('LOAD',true)
            this.$api.post(`/contacts/:instance/add-all`, {tags:oldTags, newTags:newTags, fields:this.fields, url:this.fileToSend.url, cFields:this.cFieldLookup, processVerification: this.processVerification, fieldOptions: this.fieldOptions}).then (() =>{
                this.checking = true
				this.importTrigger = true;
            })
            .catch(() => {
                this.$message.error('There was an error importing contacts. Please try again.')
            })
            .finally( () => {
                this.$store.commit('LOAD',false)
                this.parsedResults = null
                if (this.settings.app && this.settings.app.options && this.settings.app.options.leadSource &&this.settings.app.options.leadSource.length == 0) {
                    this.$store.commit('ADD_NEW_SOURCE')
                }
                this.$notification['success']({
                    message: 'Import Complete',
                    description: `Your contacts have been imported successfully.\n${new Date()}`,
                    duration: 4
                });
            })


        },
        selectField(item, index) {
			if (item == 'ignore'){
				let fieldOptions = this.fieldOptions
				if (fieldOptions.hasOwnProperty(index)){
					delete fieldOptions[index]
					this.fieldOptions = fieldOptions
				}
			}
            let fields = this.fields
            fields[index] = item
            this.fields = fields
            this.$forceUpdate()
        },
		preserveField(item, index) {
			let fieldOptions = this.fieldOptions
			if (fieldOptions.hasOwnProperty(index)){
				delete fieldOptions[index]
			} else {
				fieldOptions[index] = {
					preserveOriginal: true
				}
			}
			this.fieldOptions = fieldOptions
			this.$forceUpdate()
		},
        parseResults(array){
            let headers = array[0]
            if (headers && headers.length){
                let headerArray = []
                array[0] = headers.forEach( (h) => {
                    h = h.trim()
                    if (headerArray.includes(h)){

                        let copied = 1
                        let headerName = h + ` (${copied})`

                        while(headerArray.includes(headerName)){
                            copied++
                            headerName = h + ` (${copied})`
                        }

                        return headerArray.push(headerName)

                    }
                    return headerArray.push(h)
                })
                array[0] = headerArray
            }
            return array
        },

        parseFile(file){
            let self = this
            // self.loading = true
            if (file.url) {
                this.$papa.parse(file.url, {
                    download:true,
                    complete: function(results) {
                        // self.parsedResults = results.data
                        self.parsedResults = self.parseResults(results.data)
                        self.loading = false
						self.fields = []
                    }
                })
            } else {
                this.$papa.parse(file, {
                    download:true,
                    complete: function(results) {
                        // self.parsedResults = results.data
                        self.parsedResults = self.parseResults(results.data)
                        self.loading = false
						self.fields = []
                    }
                })
            }
        },
        handleChange(info) {
            if (info.status !== 'uploading') {
                this.parseFile(info)
            }
        },
        beforeUpload(file) {
            this.fileToSend = {
                url:null,
                file:null
            }
            const validFile = (file.type && file.type === 'application/vnd.ms-excel') || (file.type && file.type.includes('csv')) || (file.ext && file.ext.includes('.csv'))

            if (!validFile) {
                return this.$message.error('You can only upload a CSV file!');
            }
            if (file.ext && file.url) this.fileToSend.url = file.url
            else this.fileToSend.file = file

            const isLt2M = file.size / 1024 / 1024 < 10;

            if (!isLt2M) {
                return this.$message.error('CSV must be smaller than 10MB!');
            }
            if (validFile && isLt2M){
                this.loading = true
                this.handleChange(file)

            }

            return false
        },

		onNextStep(){
			this.showError = false
            let hasError = this.hasError()

            if (hasError) {
                this.showError = true
                return this.$message.error('There are errors in your submission')
            }

			this.current++;

			if(this.instance.isVerificationsOn){
				this.getVerificationCost()
			}
		},

		getVerificationCost() {
			if(!this.currency || !this.fileToSend.url){
				return;
			}
			this.loading = true
			this.$api.post(`contacts/verification-charges`, {
				currency: this.currency,
				url: this.fileToSend.url
			}).then(({data}) => {
				this.charges = data.charges
			}).catch((err) => {
				console.error(
					"Error while stopping current import process",
					err
				);
				this.$message.error(
					(err.response &&
						err.response.data &&
						err.response.data.message) ||
					"Error while stopping current import process. Please try again!"
				);
			}).finally(() => {
				this.loading = false;
			});
		},

		cancelImport() {
			if (this.$p < 40){
				return this.$message.error('You do not have permission to stop import process.')
            }
			let self = this;
			this.$confirm({
				title: "Stop Import Process",
				content: (h) => <div>Do you want stop current import process?</div>,
				okText: "STOP",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.$api.post(`/contacts/:instance/cancel-import`).then(() => {
                        self.$message.success('Import process stopped successfully.')
                    }).catch((err) => {
						console.error(
							"Error while stopping current import process",
							err
						);
						self.$message.error(
							(err.response &&
								err.response.data &&
								err.response.data.message) ||
							"Error while stopping current import process. Please try again!"
						);
					});
				},
				onCancel() {
					console.log("Cancel");
				},
			});
		},

		downloadFile(type) {
			const urls = {
				sample: 'https://ss3.nyc3.cdn.digitaloceanspaces.com/assets/sample-import-csv-files/CRM-SAMPLE%20file%20to%20Import%20Leads.xlsx',
				template: 'https://ss3.nyc3.cdn.digitaloceanspaces.com/assets/sample-import-csv-files/CRM-TEMPLATE%20to%20Import%20Leads.xlsx'
			};
			window.open(urls[type], '_blank');
		}
    },
    created(){
		if(this.instance.isVerificationsOn){
			this.processVerification = true;
		}
    },

	beforeDestroy () {
		clearInterval(this.checkTimeout)
	},

};
</script>

<style lang="scss" scoped>
.link {
	color: var(--primary);
	cursor: pointer;
}
</style>
